import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
    standalone: true
})
export class PaginatePipe implements PipeTransform {

    transform(array: any[], pageSize: number | string, pageNumber: number, backEndPagination?: boolean): any[] {
        if (!array || !array.length) return [];
        if (array.length < Number(pageSize) + 1 || !!backEndPagination) {
            return array.slice(0, Number(pageSize));
        }
        return array.slice(pageNumber * parseInt(pageSize.toString()), (pageNumber + 1) * parseInt(pageSize.toString()));
    }

}
